import styled from "styled-components";
import { BP, Colors, Rem } from "../../commons/Theme";

export const StyledCrowdPlan = styled.div`
  min-width: ${Rem(300)};
  max-width: ${Rem(300)};
  padding: ${Rem(10)};
`;

export const StyledDescription = styled.div`
  font-size: ${Rem(18)};
  text-align: right;
  padding-right: ${Rem(10)};
  padding-bottom: ${Rem(4)};
`;

export const StyledName = styled.div`
  font-size: ${Rem(24)};
  text-align: right;
  padding-right: ${Rem(16)};
  padding-bottom: ${Rem(16)};
  text-transform: uppercase;
`;

export const StyledDeliveryInfo = styled.div`
  position: absolute;
  top: ${Rem(-8)};
  left: ${Rem(-70)};
  background: ${Colors.yellow};
  transform: rotate(-45deg);
  width: 75%;
`;

export const StyledDeliveryInfoImg = styled.img`
  padding-top: ${Rem(6)};
  width: ${Rem(40)};
  transform: rotate(45deg);
`;

export const StyledDeliveryInfoText = styled.div`
  color: ${Colors.black};
  font-size: ${Rem(14)};
  margin-bottom: ${Rem(4)};
`;

export const StyledPlanBox = styled.div`
  font-size: ${Rem(32)};
  border: ${Rem(4)} solid ${Colors.yellow};
  border-radius: ${Rem(20)};
  padding: ${Rem(30)} ${Rem(20)} ${Rem(40)};
  position: relative;
  overflow: hidden;
`;

export const StyledPriceRows = styled.div`
  line-height: ${Rem(32)};
  font-size: ${Rem(22)};
  margin-bottom: ${Rem(18)};
`;

export const StyledInitialPrice = styled.span`
  font-size: ${Rem(32)};
  color: ${Colors.white};
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 45%;
    right: 0;
    border-top: 4px solid;
    border-color: ${Colors.yellow};
    transform: skewY(-30deg);
  }
`;

export const StyledFinalPrice = styled.span`
  font-size: ${Rem(32)};
`;

export const StyledSectionTitle = styled.div`
  margin-top: ${Rem(40)};
  border-top: 1px solid ${Colors.yellow};
  font-size: ${Rem(14)};
  text-align: left;
  padding: ${Rem(10)} 0 ${Rem(20)};
`;

export const StyledSection = styled.div`
`;

export const StyledIncludedItems = styled.div`
  color: ${Colors.yellow};
  font-size: ${Rem(20)};
  text-align: left;
  margin-bottom: ${Rem(20)};

  &:after {
    color: ${Colors.white};
    content: '+';
    display: block;
    width: ${Rem(20)};
  }
`;

export const StyledSectionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: ${Rem(22)};
`;

export const StyledSectionItemImage = styled.img`
  display: none;
  width: ${Rem(34)};
  margin-right: ${Rem(24)};

  @media (${BP.ipad}) {
    display: inline-block;
  }
`;

export const StyledSectionItemText = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  font-weight: lighter;
  text-align: left;
`;

export const StyledBottomText = styled.div`
  border-radius: ${Rem(20)};
  background: ${Colors.yellow};
  color:  ${Colors.white};
  font-size: ${Rem(16)};
  padding: ${Rem(12)};
  position: relative;
  top: ${Rem(-48)};
`;

export const StyledCta = styled.div`
  text-align: center;
  width: 100%;
`;

export const StyledStartAvailability = styled.div`
  font-size: ${Rem(16)};
  margin-top: ${Rem(16)};
`;
